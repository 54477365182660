import React, { Component } from 'react';
import { onboardingSteps } from 'react-common/constants';
import { Localize } from 'react-common/services';
import { connect } from 'react-redux';
import queryString from 'query-string';

import OnboardingService from '../../_services/Onboarding';
import OnboardingCover from '../../_components/Onboarding/OnboardingCover/OnboardingCover';
import OnboardingHeader from '../../_components/Onboarding/OnboardingHeader/OnboardingHeader';
import OnboardingFooter from '../../_components/Onboarding/OnboardingFooter/OnboardingFooter';
import OnboardingSupplierCialAccountContainer from './OnboardingSupplierCialAccount/OnboardingSupplierCialAccountContainer';
import OnboardingSupplierConfirmationContainer from './OnboardingSupplierConfirmation/OnboardingSupplierConfirmationContainer';
import OnboardingSupplierFinished from './OnboardingSupplierFinished/OnboardingSupplierFinished';
import OnboardingSupplierIntroductionContainer from './OnboardingSupplierIntroduction/OnboardingSupplierIntroductionContainer';
import OnboardingSupplierBirContainer from './OnboardingSupplierBir/OnboardingSupplierBirContainer';
import OnboardingSupplierQuestionnaireContainer from './OnboardingSupplierQuestionnaire/OnboardingSupplierQuestionnaireContainer';
import OnboardingSupplierPaymentContainer from './OnboardingSupplierPayment/OnboardingSupplierPaymentContainer';
import PageNotFound from '../../PageNotFound/PageNotFound';
import OnboardingWorkflowClosed from '../../_components/OnboardingWorkflowClosed/OnboardingWorkflowClosed';
import { getWorkflowForOnboarding } from '../../_actions';
import UserService from '../../_services/User';
import IntercomService from '../../_services/Intercom';
import Loader from 'react-common/components/Loader/Loader';

class OnboardingSupplier extends Component {
  state = {
    onboardingStep: '',
    dropdownLanguage: '', // dummy state, used just for rerendering component when language is changed,
    questionnaireSubmitted: false
  };

  componentDidMount() {
    const {
      dispatchGetWorkflowForOnboarding,
      workflow,
      workflowId,
      supplierId
    } = this.props;

    UserService.logout();

    if (!workflow) {
      dispatchGetWorkflowForOnboarding(workflowId, supplierId);
    }
  }

  setQuestionnaireFormAsSubmitted = () =>
    this.setState({ questionnaireSubmitted: true });

  changeLanguage = language => {
    Localize.setLanguage(language);
    // rerender component to set proper language in dropdown language
    this.setState({
      dropdownLanguage: language
    });
    //store language to localstorage
    localStorage.setItem('supplier360_language', language);
    IntercomService.show();
  };

  changeOnboardingStep = onboardingStep => this.setState({ onboardingStep });

  render() {
    const { showPageNotFound, showLoader, workflow, supplier } = this.props;

    if (showLoader) {
      return <Loader loading={true} />;
    }

    if (showPageNotFound) {
      return <PageNotFound className="Onboarding-404" />;
    }

    if (workflow.isClosed()) {
      return <OnboardingWorkflowClosed workflow={workflow} />;
    }

    let onboardingPage;
    let language = 'en';
    let onboardingStep =
      this.state.onboardingStep || onboardingSteps.INTRODUCTION;

    if (supplier) {
      if (supplier.getCountry()) {
        language =
          localStorage.getItem('supplier360_language') ||
          Localize.getLanguageForCountry(supplier.getCountry());
        Localize.setLanguage(language);
      }
      onboardingStep =
        this.state.onboardingStep || supplier.getOnboardingStep();

      if (
        supplier.getOnboardingStep() === onboardingSteps.FINISHED &&
        workflow.isQuestionnaireReport() &&
        !this.state.questionnaireSubmitted
      ) {
        onboardingStep = onboardingSteps.QUESTIONNAIRE;
      }

      // if supplier already created account and it is not authenticated, show cial account login form
      if (
        supplier.getAccountId() &&
        !OnboardingService.isSupplierAuthenticated(
          workflow.getId(),
          supplier.getId()
        )
      ) {
        onboardingStep = onboardingSteps.CIAL_ACCOUNT;
      }
    }

    switch (onboardingStep) {
      case onboardingSteps.INTRODUCTION:
        onboardingPage = (
          <OnboardingSupplierIntroductionContainer
            changeOnboardingStep={this.changeOnboardingStep}
            {...this.props}
          />
        );
        break;
      case onboardingSteps.CIAL_ACCOUNT:
        onboardingPage = (
          <OnboardingSupplierCialAccountContainer
            changeOnboardingStep={this.changeOnboardingStep}
            {...this.props}
          />
        );
        break;
      case onboardingSteps.BIR_FORM:
        onboardingPage = (
          <OnboardingSupplierBirContainer
            changeOnboardingStep={this.changeOnboardingStep}
            language={language}
            {...this.props}
          />
        );
        break;
      case onboardingSteps.QUESTIONNAIRE:
        onboardingPage = (
          <OnboardingSupplierQuestionnaireContainer
            changeOnboardingStep={this.changeOnboardingStep}
            setQuestionnaireFormAsSubmitted={
              this.setQuestionnaireFormAsSubmitted
            }
            {...this.props}
          />
        );
        break;
      case onboardingSteps.PAYMENT:
        onboardingPage = (
          <OnboardingSupplierPaymentContainer
            changeOnboardingStep={this.changeOnboardingStep}
            {...this.props}
          />
        );
        break;
      case onboardingSteps.CONFIRMATION_CALL:
        onboardingPage = (
          <OnboardingSupplierConfirmationContainer
            changeOnboardingStep={this.changeOnboardingStep}
            {...this.props}
          />
        );
        break;
      case onboardingSteps.FINISHED:
        onboardingPage = (
          <OnboardingSupplierFinished
            changeOnboardingStep={this.changeOnboardingStep}
            {...this.props}
          />
        );
        break;
      default:
        onboardingPage = null;
    }

    return (
      <>
        <OnboardingHeader
          language={language}
          changeLanguage={this.changeLanguage}
        />

        {onboardingStep === onboardingSteps.INTRODUCTION && onboardingPage}

        {onboardingStep !== onboardingSteps.INTRODUCTION && (
          <div className="Onboarding-content">
            <OnboardingCover workflow={workflow} />
            <div className="container">
              <div className="card">
                <div className="Onboarding-main">{onboardingPage}</div>
              </div>
            </div>
          </div>
        )}

        <OnboardingFooter />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { workflows, form } = state;
  const {
    workflow_id: workflowId,
    supplier_id: supplierId
  } = props.match.params;
  const workflow = workflows.getById[workflowId];
  const supplier = workflow && workflows.suppliers.getById[supplierId];

  let formValues = {};

  if (form['onboarding'] && form['onboarding'].values) {
    formValues = form['onboarding'].values;
  }

  const queryParams = queryString.parse(props.location.search);
  // if supplier didn't yet created mycial account, show him email from query parameter
  if (supplier && !supplier.getAccountId() && queryParams.email) {
    supplier.invite.email = queryParams.email;
  }

  return {
    workflowId,
    supplierId,
    workflow,
    supplier,
    formValues,
    showPageNotFound: supplierId && !supplier && !workflows.isFetching,
    showLoader: (supplierId && !supplier) || workflows.isFetching || !workflow,
    formError: workflows.error
  };
};

const mapDispatchToProps = {
  dispatchGetWorkflowForOnboarding: getWorkflowForOnboarding
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingSupplier);
