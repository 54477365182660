import get from 'lodash/get';
import React, { Component } from 'react';
import { MomentDate, Tooltip } from 'react-common/components';
import { languages, supplierStatuses } from 'react-common/constants';
import { Localize } from 'react-common/services';
import { Panel, PanelGroup } from 'react-bootstrap';

import { reportTypeToName } from '../../../_constants/workflows';

class WorkflowsSupplierDetailsCompliance extends Component {
  render() {
    const {
      workflow,
      supplier,
      user,
      downloadCfrReport,
      handleScreenSupplier
    } = this.props;

    const complianceProduct = user.getCfrProduct();
    const screening = supplier.getLastScreening();
    const isScreeningDelivered = !!(
      screening &&
      screening.reports &&
      screening.reports.length
    );
    const complianceLimitReached =
      complianceProduct.used >= complianceProduct.total;
    const complianceDisabled =
      !user.isComplianceEnabled() ||
      !user.isCompanyAdmin() ||
      complianceLimitReached;

    let tooltipText = '';
    if (complianceLimitReached) {
      tooltipText =
        'You reached product limit. Please contact support for upgrade.';
    } else if (!isScreeningDelivered) {
      tooltipText = `You can request CFR only after ${
        reportTypeToName[workflow.getReportType()]
      } is delivered`;
    }

    const canOrderCFR =
      supplier.getStatus() === supplierStatuses.COMPLETED ? true : false;

    return (
      <PanelGroup
        id="supplier-details-screening-accordion"
        accordion
        defaultActiveKey="1"
      >
        <Panel className="WorkflowsCreate-panel Screen-panel" eventKey="1">
          <Panel.Heading>
            <Panel.Title toggle>
              <div className="WorkflowsSupplierDetails-title-table no-border">
                {!supplier.hasScreening() && (
                  <>
                    <span className="WorkflowsSupplierDetails-title-table-heading">
                      CFR Report
                    </span>
                    <span className="WorkflowsSupplierDetails-status-label">
                      Not requested
                    </span>
                  </>
                )}

                {supplier.isScreeningInProgress() && (
                  <>
                    <span className="WorkflowsSupplierDetails-title-table-heading">
                      CFR Report
                    </span>
                    <span className="WorkflowsSupplierDetails-status-label">
                      Pending
                    </span>
                  </>
                )}

                {supplier.hasScreening() &&
                  !supplier.isScreeningInProgress() && (
                    <>
                      <div>
                        <div className="WorkflowsSupplierDetails-title-table-heading">
                          CFR Report
                        </div>
                        <div className="WorkflowsSupplierDetails-last-update ScreenCheckDate">
                          Last check date:&nbsp;
                          <MomentDate
                            format="MMMM DD, YYYY"
                            locale={
                              languages[Localize.getLanguage()].uploadcare
                            }
                            date={get(screening, 'request.requested_at')}
                          />
                        </div>
                      </div>

                      <div className="pull-right CFR-btns">
                        <Tooltip
                          text="Download full CFR Report"
                          position="bottom"
                          id="supplier-details-ccr"
                          className="Title-tooltip"
                        >
                          <button
                            className="Btn-default-small Btn-default-primary Btn-download-report"
                            onClick={e => {
                              e.stopPropagation();
                              downloadCfrReport(get(screening, '_id'));
                            }}
                          >
                            Download
                          </button>
                        </Tooltip>
                      </div>
                    </>
                  )}
              </div>
              {/* <span className="pull-right">
                <i className="icon-ic_expand-arrow" />
              </span> */}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Collapse>
            <Panel.Body className="ScreenPanelBodyWrapper">
              {!supplier.hasScreening() && (
                <div className="row">
                  <div className="WorkflowsSupplierDetails-results-data">
                    <div className="WorkflowsSupplierDetails-content-table">
                      <div className="row">
                        <div className="Not-requested-state">
                          <p className="text-center">
                            You haven’t requested Compliance Findings Report for
                            this company yet. <br />
                            Requesting a CFR gives you access to the full
                            compliance data.
                          </p>

                          {user.isCompanyAdmin() && (
                            <Tooltip
                              text={tooltipText}
                              position="bottom"
                              id="supplier-details-request-cfr"
                            >
                              <button
                                className={`Btn-default-small Btn-default-primary ${
                                  complianceDisabled || !canOrderCFR
                                    ? 'disabled'
                                    : ''
                                }`}
                                onClick={() =>
                                  !complianceDisabled &&
                                  canOrderCFR &&
                                  handleScreenSupplier()
                                }
                              >
                                Request CFR
                              </button>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {supplier.isScreeningInProgress() && (
                <div className="row">
                  <div className="WorkflowsSupplierDetails-results-data">
                    <div className="WorkflowsSupplierDetails-content-table">
                      <div className="row">
                        <div className="Screen-pending-state">
                          <p className="text-center WorkflowsSupplierDetails-bir-alert">
                            We are creating the CFR, this should take about 10
                            minutes; feel free to keep going and return to this
                            page later.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* {supplier.hasScreening() &&
                !supplier.isScreeningInProgress() && (
                  <>
                    <PanelGroup
                      id="supplier-details-screening-accordion"
                      accordion
                      defaultActiveKey="1"
                    >
                      <Panel
                        className="WorkflowsCreate-panel Screen-panel no-border"
                        eventKey="1"
                      >
                        <Panel.Heading>
                          <Panel.Title toggle>
                            <div className="WorkflowsSupplierDetails-title-table Screen-title">
                              Company
                              <span
                                className={`Results-status Badge ${
                                  supplier.companyResultsFound()
                                    ? 'Bg-mint'
                                    : 'Bg-neutral-grey'
                                }`}
                              >
                                {supplier.companyResultsFound()
                                  ? 'Results found'
                                  : 'Results not found'}
                              </span>
                            </div>

                            <span className="pull-right">
                              <i className="icon-ic_expand-arrow" />
                            </span>
                          </Panel.Title>
                        </Panel.Heading>
                        <Panel.Collapse>
                          <Panel.Body>
                            <div className="row">
                              <div className="WorkflowsSupplierDetails-results-data">
                                <div className="WorkflowsSupplierDetails-content-table">
                                  <div className="row">
                                    <Table responsive>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <ul>
                                              <li>Sanctions lists</li>
                                            </ul>
                                          </td>
                                          <td>
                                            <span
                                              className={`Badge ${
                                                supplier.companySanctionListsFound()
                                                  ? 'Bg-light-orange'
                                                  : 'Bg-grey'
                                              }`}
                                            >
                                              {supplier.companySanctionListsFound()
                                                ? 'Match'
                                                : 'No match'}
                                            </span>
                                          </td>
                                          <td>
                                            {supplier.companyNumberOfSanctionLists()}{' '}
                                            lists
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <ul>
                                              <li>Adverse media</li>
                                            </ul>
                                          </td>
                                          <td>
                                            <span
                                              className={`Badge ${
                                                supplier.companyAdverseMediaFound()
                                                  ? 'Bg-light-orange'
                                                  : 'Bg-grey'
                                              }`}
                                            >
                                              {supplier.companyAdverseMediaFound()
                                                ? 'Match'
                                                : 'No match'}
                                            </span>
                                          </td>
                                          <td>
                                            {supplier.companyNumberOfAdverseMedia()}{' '}
                                            lists
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <ul>
                                              <li>Ownership report</li>
                                            </ul>
                                          </td>
                                          <td>
                                            <span
                                              className={`Badge ${
                                                supplier.companyOwnershipReportAvailable()
                                                  ? 'Bg-light-orange'
                                                  : 'Bg-grey'
                                              }`}
                                            >
                                              {supplier.companyOwnershipReportAvailable()
                                                ? 'Available'
                                                : 'Not available'}
                                            </span>
                                          </td>
                                          <td>
                                            {supplier.companyNumberOfAssociatedIndividuals()}{' '}
                                            associated individuals
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <ul>
                                              <li>Family tree report</li>
                                            </ul>
                                          </td>
                                          <td>
                                            <span
                                              className={`Badge ${
                                                supplier.companyFamilyTreeReportAvailable()
                                                  ? 'Bg-light-orange'
                                                  : 'Bg-grey'
                                              }`}
                                            >
                                              {supplier.companyFamilyTreeReportAvailable()
                                                ? 'Available'
                                                : 'Not available'}
                                            </span>
                                          </td>
                                          <td>
                                            {supplier.companyNumberOfAssociatedCompanies()}{' '}
                                            associated companies
                                          </td>
                                        </tr>

                                        {supplier.isScreeningForBrazil() && (
                                          <tr>
                                            <td>
                                              <ul>
                                                <li>Criminal actions</li>
                                              </ul>
                                            </td>
                                            <td>
                                              <span
                                                className={`Badge ${
                                                  supplier.companyCriminalActionsFound()
                                                    ? 'Bg-light-orange'
                                                    : 'Bg-grey'
                                                }`}
                                              >
                                                {supplier.companyCriminalActionsFound()
                                                  ? 'Available'
                                                  : 'Not available'}
                                              </span>
                                            </td>
                                            <td>
                                              {supplier.companyNumberOfCriminalActions()}{' '}
                                              criminal actions
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Panel.Body>
                        </Panel.Collapse>
                      </Panel>
                    </PanelGroup>
                    {supplier
                      .getComplianceRequestShareholders()
                      .map((shareholder, index) => (
                        <PanelGroup
                          id="supplier-details-screening-accordion"
                          accordion
                          defaultActiveKey="1"
                          key={index}
                        >
                          <Panel
                            className="WorkflowsCreate-panel Screen-panel no-border"
                            eventKey="1"
                          >
                            <Panel.Heading>
                              <Panel.Title toggle>
                                <div className="WorkflowsSupplierDetails-title-table Screen-title">
                                  {shareholder}

                                  <span
                                    className={`Results-status Badge ${
                                      supplier.shareholderResultsFound(index)
                                        ? 'Bg-mint'
                                        : 'Bg-neutral-grey'
                                    }`}
                                  >
                                    {supplier.shareholderResultsFound(index)
                                      ? 'Results found'
                                      : 'Results not found'}
                                  </span>
                                </div>
                                <span className="pull-right">
                                  <i className="icon-ic_expand-arrow" />
                                </span>
                              </Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse>
                              <Panel.Body>
                                <div className="row">
                                  <div className="WorkflowsSupplierDetails-results-data">
                                    <div className="WorkflowsSupplierDetails-content-table">
                                      <div className="row">
                                        <Table responsive>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <ul>
                                                  <li>Sanctions lists</li>
                                                </ul>
                                              </td>
                                              <td>
                                                <span
                                                  className={`Badge ${
                                                    supplier.shareholderSanctionListsFound(
                                                      index
                                                    )
                                                      ? 'Bg-light-orange'
                                                      : 'Bg-grey'
                                                  }`}
                                                >
                                                  {supplier.shareholderSanctionListsFound(
                                                    index
                                                  )
                                                    ? 'Match'
                                                    : 'No match'}
                                                </span>
                                              </td>
                                              <td>
                                                {supplier.shareholderNumberOfSanctionLists(
                                                  index
                                                )}{' '}
                                                lists
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <ul>
                                                  <li>PEPs</li>
                                                </ul>
                                              </td>
                                              <td>
                                                <span
                                                  className={`Badge ${
                                                    supplier.shareholderPepsFound(
                                                      index
                                                    )
                                                      ? 'Bg-light-orange'
                                                      : 'Bg-grey'
                                                  }`}
                                                >
                                                  {supplier.shareholderPepsFound(
                                                    index
                                                  )
                                                    ? 'Match'
                                                    : 'No match'}
                                                </span>
                                              </td>
                                              <td />
                                            </tr>
                                            <tr>
                                              <td>
                                                <ul>
                                                  <li>Adverse media</li>
                                                </ul>
                                              </td>
                                              <td>
                                                <span
                                                  className={`Badge ${
                                                    supplier.shareholderAdverseMediaFound(
                                                      index
                                                    )
                                                      ? 'Bg-light-orange'
                                                      : 'Bg-grey'
                                                  }`}
                                                >
                                                  {supplier.shareholderAdverseMediaFound(
                                                    index
                                                  )
                                                    ? 'Match'
                                                    : 'No match'}
                                                </span>
                                              </td>
                                              <td>
                                                {supplier.shareholderNumberOfAdverseMedia(
                                                  index
                                                )}{' '}
                                                links
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Panel.Body>
                            </Panel.Collapse>
                          </Panel>
                        </PanelGroup>
                      ))}
                  </>
                )} */}
            </Panel.Body>
          </Panel.Collapse>
        </Panel>
      </PanelGroup>
    );
  }
}

export default WorkflowsSupplierDetailsCompliance;
