import { Auth } from 'react-common/services';
import { push } from 'connected-react-router';
import { SubmissionError } from 'redux-form';
import { Localize } from 'react-common/services';

import { handleError } from './handler';
import UserService from '../_services/User';
import IntercomService from '../_services/Intercom';
import userConstants from '../_constants/user';

function reloadUserRequest(showLoader) {
  return {
    type: userConstants.RELOAD_REQUEST,
    isFetching: showLoader
  };
}

function reloadUserSuccess(user) {
  return {
    type: userConstants.RELOAD_SUCCESS,
    user
  };
}

function reloadUserFailure(error) {
  return {
    type: userConstants.RELOAD_FAILURE,
    error
  };
}

export function reloadUser(showLoader = false) {
  return dispatch => {
    dispatch(reloadUserRequest(showLoader));
    return UserService.reload()
      .then(user => dispatch(reloadUserSuccess(user)))
      .catch(handleError(dispatch, reloadUserFailure));
  };
}

function loginRequest() {
  return {
    type: userConstants.LOGIN_REQUEST
  };
}

function loginSuccess(user) {
  return {
    type: userConstants.LOGIN_SUCCESS,
    user
  };
}

function loginFailure(error) {
  return {
    type: userConstants.LOGIN_FAILURE,
    error
  };
}

export function login(user, nextUrl = '/home', logAsEmail) {
  return dispatch => {
    dispatch(loginRequest());
    const { email, password } = user;
    return UserService.login(email, password, logAsEmail)
      .then(user => {
        dispatch(loginSuccess(user));
        dispatch(push(nextUrl));
      })
      .catch(err => {
        handleError(dispatch, loginFailure)(err);
        throw new SubmissionError({
          _error: err
        });
      });
  };
}

function logoutRequest() {
  return {
    type: userConstants.LOGOUT
  };
}

export function logout() {
  return dispatch => {
    dispatch(logoutRequest());
    UserService.logout();
    return dispatch(push('/'));
  };
}

function forgotPasswordRequest() {
  return {
    type: userConstants.FORGOT_PASSWORD_REQUEST
  };
}

function forgotPasswordSuccess(email) {
  return {
    type: userConstants.FORGOT_PASSWORD_SUCCESS,
    email
  };
}

function forgotPasswordFailure(error) {
  return {
    type: userConstants.FORGOT_PASSWORD_FAILURE,
    error
  };
}

export function forgotPassword(email) {
  return dispatch => {
    dispatch(forgotPasswordRequest());
    return UserService.forgotPassword(email)
      .then(() => dispatch(forgotPasswordSuccess(email)))
      .catch(handleError(dispatch, forgotPasswordFailure));
  };
}

function changePasswordRequest() {
  return {
    type: userConstants.CHANGE_PASSWORD_REQUEST
  };
}

function changePasswordSuccess() {
  return {
    type: userConstants.CHANGE_PASSWORD_SUCCESS
  };
}

export function changePasswordFailure(error) {
  return {
    type: userConstants.CHANGE_PASSWORD_FAILURE,
    error
  };
}

export function changePassword(token, password) {
  return dispatch => {
    dispatch(changePasswordRequest());
    return UserService.changePassword(token, password)
      .then(() => {
        // show a success message, then after two seconds (UX my friend!) redirect to login
        dispatch(changePasswordSuccess());
        setTimeout(() => dispatch(push('/login')), 2000);
      })
      .catch(handleError(dispatch, changePasswordFailure));
  };
}

function changeAccountSettingsRequest() {
  return {
    type: userConstants.CHANGE_ACCOUNT_SETTINGS_REQUEST
  };
}

function changeAccountSettingsSuccess(user) {
  return {
    type: userConstants.CHANGE_ACCOUNT_SETTINGS_SUCCESS,
    user
  };
}

export function changeAccountSettingsFailure(error) {
  return {
    type: userConstants.CHANGE_ACCOUNT_SETTINGS_FAILURE,
    error
  };
}

export function changeAccountSettings(account) {
  return dispatch => {
    dispatch(changeAccountSettingsRequest());
    return UserService.changeAccountSettings(account)
      .then(updatedAccount => {
        const { _id, info, companies, preferences } = updatedAccount;
        Auth.setUser({ _id, info, companies, preferences });
        if (Localize.getLanguage() !== preferences.language) {
          Localize.setLanguage(preferences.language);
          IntercomService.show();
        }
        dispatch(changeAccountSettingsSuccess(updatedAccount));
      })
      .catch(handleError(dispatch, changeAccountSettingsFailure));
  };
}

function resetPasswordRequest() {
  return {
    type: userConstants.RESET_PASSWORD_REQUEST
  };
}

function resetPasswordSuccess() {
  return {
    type: userConstants.RESET_PASSWORD_SUCCESS
  };
}

export function resetPasswordFailure(error) {
  return {
    type: userConstants.RESET_PASSWORD_FAILURE,
    error
  };
}

export function resetPassword(oldPassword, newPassword) {
  return dispatch => {
    dispatch(resetPasswordRequest());
    return UserService.resetPassword(oldPassword, newPassword)
      .then(() => dispatch(resetPasswordSuccess()))
      .catch(handleError(dispatch, resetPasswordFailure));
  };
}

function setSuppliersColumnsRequest(columns) {
  return {
    type: userConstants.SUPPLIERS_COLUMNS_REQUEST,
    columns
  };
}

function setSuppliersColumnsSuccess(user) {
  return {
    type: userConstants.SUPPLIERS_COLUMNS_SUCCESS,
    user
  };
}

export function setSuppliersColumnsFailure(error) {
  return {
    type: userConstants.SUPPLIERS_COLUMNS_FAILURE,
    error
  };
}

export function setSuppliersColumns(columns) {
  return dispatch => {
    dispatch(setSuppliersColumnsRequest(columns));
    return UserService.changeAccountSettings({
      preferences: { supplier360: { supplier_columns: columns } }
    })
      .then(user => {
        dispatch(setSuppliersColumnsSuccess(user));
        Auth.setUser(user);
      })
      .catch(handleError(dispatch, setSuppliersColumnsFailure));
  };
}

function setSupplierTagsRequest() {
  return {
    type: userConstants.SET_SUPPLIER_TAGS_REQUEST
  };
}

function setSupplierTagsSuccess(user) {
  return {
    type: userConstants.SET_SUPPLIER_TAGS_SUCCESS,
    user
  };
}

function setSupplierTagsFailure(error) {
  return {
    type: userConstants.SET_SUPPLIER_TAGS_FAILURE,
    error
  };
}

export function setSupplierTags(user, tags) {
  return dispatch => {
    dispatch(setSupplierTagsRequest());
    return UserService.setSupplierTags(user, tags)
      .then(() => UserService.reload())
      .then(user => dispatch(setSupplierTagsSuccess(user)))
      .catch(handleError(dispatch, setSupplierTagsFailure));
  };
}

function deleteSupplierTagRequest() {
  return {
    type: userConstants.DELETE_SUPPLIER_TAG_REQUEST
  };
}

function deleteSupplierTagSuccess(user) {
  return {
    type: userConstants.DELETE_SUPPLIER_TAG_SUCCESS,
    user
  };
}

function deleteSupplierTagFailure(error) {
  return {
    type: userConstants.DELETE_SUPPLIER_TAG_FAILURE,
    error
  };
}

export function deleteSupplierTag(user, tagId) {
  return dispatch => {
    dispatch(deleteSupplierTagRequest());
    return UserService.deleteSupplierTag(user, tagId)
      .then(() => UserService.reload())
      .then(user => dispatch(deleteSupplierTagSuccess(user)))
      .catch(handleError(dispatch, deleteSupplierTagFailure));
  };
}
