import Intercom, {
  show as intercomShow,
  hide as intercomHide
} from '@intercom/messenger-js-sdk';

/**
 * Service class to handle Intercom messenger integration
 */
class IntercomService {
  /**
   * Initialize Intercom messenger with user data
   * @param {Object} user - User object containing profile info
   * @param {Object} user.info - Basic user info like email, name and image
   * @param {Array} user.companies - List of companies user belongs to
   * @param {Object} user.preferences - User preferences like language
   */
  initialize(user = {}) {
    const {
      info: { email, name, image } = {},
      companies = [],
      preferences: { language } = {}
    } = user;
    const intercomConfig = {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      user_id: email,
      name,
      email,
      language_override: language,
      hide_default_launcher: true,
      avatar: image
        ? {
            type: 'avatar',
            image_url: image
          }
        : undefined,
      companies:
        companies.length > 0
          ? companies.map(item => ({
              company_id: item.company._id,
              name: item.company.info.legal_name
            }))
          : undefined
    };
    const cleanConfig = Object.fromEntries(
      Object.entries(intercomConfig).filter(([_, value]) => value !== undefined)
    );
    Intercom(cleanConfig);
    console.log('Intercom initialized', cleanConfig);
  }

  /**
   * Show the Intercom messenger
   */
  show() {
    intercomShow();
  }

  /**
   * Hide the Intercom messenger
   */
  hide() {
    intercomHide();
  }
}

export default new IntercomService();
