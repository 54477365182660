import '@babel/polyfill';
import 'react-app-polyfill/ie9';

import './_css/fontello.css';
import './_css/bootstrap.min.css';
import './_css/bootstrap-theme.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'open-sans-all';
import 'react-common/index.css';
import './index.css';

import React from 'react';
import { Auth } from 'react-common/services';
import ReactDOM from 'react-dom';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

import ErrorFallback from './_components/ErrorFallback/ErrorFallback';
import User from './_services/User';

import App from './App/App';
import registerServiceWorker from './registerServiceWorker';
import Intercom from './_services/Intercom';

export let bugsnagClient;
let appComponent = <App />;

if (process.env.REACT_APP_BUGSNAG_KEY) {
  bugsnagClient = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    releaseStage: process.env.REACT_APP_ENV
  });
  bugsnagClient.use(bugsnagReact, React);
  const ErrorBoundary = bugsnagClient.getPlugin('react');

  appComponent = (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>
  );
}

if (Auth.isUserAuthenticated()) {
  const user = Auth.getUser();
  User.connectWithPlugins(user);
} else {
  Intercom.initialize({});
}

ReactDOM.render(appComponent, document.getElementById('root'));
registerServiceWorker();
